type Path =
  | '/intro/link-prompt'
  | '/intro/link-accounts'
  | '/intro/analyze-spending'
  | '/intro/review-budget'
  | '/plan'
  | '/budgets'
  | string;
export function getGroupUrl(groupId: string | null, path: Path) {
  if (!groupId) return '/';
  return `/groups/${groupId}${path}`;
}
